import { memo, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changePhraseInActionDataElem, changePhraseInActionEndpoint, changePhraseInActionEndpointEntry, deletePhraseInActionDataElem, deletePhraseInActionEndpoint, deletePhraseInActionEndpointEntry } from '../../../store/sesSlice';
import { changePhraseInActionRobot, changePhraseInException, deletePhraseInActionRobot, deletePhraseInException, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { IPhraseProps } from './Phrase.props';
import styles from './Phrase.module.scss';

const Phrase = ({ phrase, idxPhrase, arrayPhrases, channel, idxAction, changeFlg, setChangeFlg, actionFor }: IPhraseProps): JSX.Element => {
	const [inputPhrase, setInputPhrase] = useState<string>(phrase); // фраза
	const [maxRows, setMaxRows] = useState<number>(2); // max кол-во строк

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик увода фокуса с поля фразы
	const blurHandler = (): void => {
		setMaxRows(2);
		if (inputPhrase !== phrase) {
			if (actionFor.for === 'robotException') {
				dispatch(changePhraseInException({
					exception: actionFor.exception,
					channel,
					idxAction,
					idxPhrase,
					text: inputPhrase,
				})); // изменяем фразу в действии исключения робота
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (actionFor.for === 'endpointEntry') {
				dispatch(changePhraseInActionEndpointEntry({
					channel,
					idxAction,
					idxPhrase,
					text: inputPhrase,
				})); // изменяем фразу в действии входа КТ
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else {
				actionFor.for === 'robotEvent' && dispatch(changePhraseInActionRobot({
					actionEvent: actionFor.event,
					channel,
					idxAction,
					idxPhrase,
					text: inputPhrase,
				})); // изменяем фразу в действии события робота
				actionFor.for === 'dataElement' && dispatch(changePhraseInActionDataElem({
					actionEvent: actionFor.event,
					channel,
					idxAction,
					idxPhrase,
					text: inputPhrase,
				})); // изменяем фразу в действии ЭД
				actionFor.for === 'endpoint' && dispatch(changePhraseInActionEndpoint({
					channel,
					idxAction,
					idxPhrase,
					text: inputPhrase,
				})); // изменяем фразу в действии КТ
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		}
	};

	// обработчик удаления фразы
	const deletePhraseHandler = (): void => {
		if (actionFor.for === 'robotException') {
			dispatch(deletePhraseInException({
				exception: actionFor.exception,
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в исключении робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(deletePhraseInActionEndpointEntry({
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(deletePhraseInActionRobot({
				actionEvent: actionFor.event,
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии робота
			actionFor.for === 'dataElement' && dispatch(deletePhraseInActionDataElem({
				actionEvent: actionFor.event,
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии ЭД
			actionFor.for === 'endpoint' && dispatch(deletePhraseInActionEndpoint({
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	return (
		<div className={cn(styles.phraseBlock, {
			[styles.phraseBlockOpacity]: arrayPhrases.length - 1 === idxPhrase && inputPhrase === '' && arrayPhrases.length > 1,
		})}>
			<FormControl fullWidth margin='dense'>
				<TextField
					required={arrayPhrases.length - 1 !== idxPhrase || arrayPhrases.length === 1}
					multiline
					disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
					label={`${translate('input_phrase')} ${idxPhrase + 1}`}
					variant="outlined"
					maxRows={maxRows}
					value={inputPhrase}
					onChange={(e) => setInputPhrase(e.target.value)}
					onBlur={blurHandler}
					onFocus={() => setMaxRows(5)}
					InputProps={{
						style: {
							padding: '8px 13px',
							fontSize: 13,
							color: colorPrimary,
						},
						endAdornment: (
							<InputAdornment position="end">
								{(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && arrayPhrases.length > 1 && arrayPhrases.length - 1 !== idxPhrase && activeRobotVersion === 'draft' &&
									<div className={styles.deletePhraseButton}>
										<FontAwesomeIcon
											icon={faTrashCan}
											color={colorRed}
											size='sm'
											onClick={deletePhraseHandler}
											title={translate('buttonTitle_deletePhrase')}
											style={{ cursor: 'pointer' }}
										/>
									</div>
								}
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>
		</div>
	);
};

export default memo(Phrase);
