import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Fade, FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../store/hooks';
import { selectDataList, selectEndpointList } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { CLOUD_DATA_PRESENTATION } from '../../constants/cookieNames';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus } from '../../types/statusTypes';
import RobotData from '../Tables/RobotData/RobotData';
import CloudTag from './CloudTag/CloudTag';
import FormAddingDataElem from '../Forms/FormAddingDataElem/FormAddingDataElem';
import FormAddingEndpoint from '../Forms/FormAddingEndpoint/FormAddingEndpoint';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IWordCloudProps } from './WordCloud.props';
import styles from './WordCloud.module.scss';

const WordCloud = ({ type, setShowElem, mainfilter, setMainFilter, additionFilter, setAdditionFilter }: IWordCloudProps): JSX.Element => {
	const [showFormAdding, setShowFormAdding] = useState<boolean>(false); // показ формы добавления
	const inputFilterRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода фильтрации

	const dataElemList = useAppSelector(selectDataList); // список конечных точек
	const endpointList = useAppSelector(selectEndpointList); // список элементов данных
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const [cookies] = useCookies([CLOUD_DATA_PRESENTATION]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за id активного робота и сбрасываем фильтры
	useEffect(() => {
		setMainFilter('');
		setAdditionFilter && setAdditionFilter('');
	}, [activeRobotId]);

	return (
		<div className={styles.container}>
			{/* загрузка списка данных */}
			{(type === 'data' ? dataElemList : endpointList).status === RequestStatus.LOADING &&
				<div className={styles.loading}>
					<ProgressCircle title={translate('spinnerTitle_loading')} />
				</div>
			}

			{/* ошибка загрузки списка данных */}
			{(type === 'data' ? dataElemList : endpointList).status === RequestStatus.FAILED &&
				<div className={styles.failed}>
					<div>
						{translate((type === 'data' ? dataElemList : endpointList).message || 'title_loadFailed')}
					</div>
				</div>
			}

			{/* облако/таблица данных */}
			{(type === 'data' ? dataElemList : endpointList).status === RequestStatus.IDLE &&
				<Fade in={true} timeout={500}>
					<div className={styles.wordCloudWrapper}>
						<div className={styles.wordCloudWrapperTop}>
							{/* поиск */}
							<FormControl margin='dense' sx={{ flexGrow: 1 }}>
								<TextField
									inputRef={inputFilterRef}
									label={translate("input_search")}
									variant="outlined"
									value={mainfilter}
									onChange={(e) => setMainFilter(e.target.value)}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										endAdornment: (
											<InputAdornment position="end" >
												{mainfilter !== '' &&
													<FontAwesomeIcon
														icon={faXmark}
														onClick={() => {
															setMainFilter('');
															inputFilterRef.current?.focus();
														}}
														style={{ cursor: 'pointer ' }}
													/>
												}
											</InputAdornment>
										),
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
										'.MuiInputBase-input': { padding: '0 14px' },
										zIndex: 1,
									}}
								/>
							</FormControl>

							{/* добавление элемента данных/конечной точки */}
							{((type === 'data' && isAccess(SES.DATA_ADD)) || (type === 'endpoints' && isAccess(SES.ENDPOINT_ADD))) && activeRobotVersion === 'draft' &&
								<FormControl margin='dense'>
									<Button
										variant="outlined"
										sx={{ fontSize: 11, height: 33, flexShrink: 0 }}
										type='button'
										onClick={() => setShowFormAdding(true)}
									>
										{translate(type === 'data' ? 'button_addElement' : 'button_addEndpoint')}
									</Button>
								</FormControl>
							}
						</div>
						{(type === 'data' ? dataElemList : endpointList).data.length > 0 ?
							(cookies.cloudDataPresentation === 'false' ?
								<RobotData
									type={type}
									setShowElem={setShowElem}
									mainfilter={mainfilter}
									additionFilter={additionFilter}
								/>
								:
								// облако тегов
								<CloudTag
									type={type}
									setShowElem={setShowElem}
									mainfilter={mainfilter}
									additionFilter={additionFilter}
								/>
							)
							:
							// пустой список данных
							<div className={styles.empty}>
								<div>
									{translate('title_emptyList')}
								</div>
							</div>
						}
					</div>
				</Fade>
			}

			{showFormAdding && type === 'data' &&
				<FormAddingDataElem
					showModal={showFormAdding}
					setShowModal={setShowFormAdding}
					setShowElem={setShowElem}
				/>
			}
			{showFormAdding && type === 'endpoints' &&
				<FormAddingEndpoint
					showModal={showFormAdding}
					setShowModal={setShowFormAdding}
					setShowElem={setShowElem}
				/>
			}
		</div>
	);
};

export default WordCloud;
