import { Fragment, memo } from 'react';
import useTranslate from '../../hooks/useTranslate';
import ConditionRun from './ConditionRun/ConditionRun';
import { IConditionRunBlockProps } from './ConditionRunBlock.props';
import styles from './ConditionRunBlock.module.scss';

const ConditionRunBlock = ({ conditionDataBlock, conditionBlockIdx, arrayConditionsData, isDisable, isAvailable, changeFlg, setChangeFlg, conditionFor, addConditionRunHandler }: IConditionRunBlockProps): JSX.Element => {

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<fieldset className={styles.conditionDataBlock}>
				<legend>{translate('title_conditions')}</legend>
				{conditionDataBlock?.map((conditionData, conditionIdx) => (
					<Fragment key={conditionData.type + conditionData.id + conditionBlockIdx + conditionIdx + (conditionFor.for === 'categoryAction' && conditionFor.categoryId)}>
						{/* блоки И */}
						<ConditionRun
							conditionData={conditionData}
							conditionBlockIdx={conditionBlockIdx}
							conditionIdx={conditionIdx}
							isDisable={isDisable || (conditionFor.for === 'endpointRun' && Array.isArray(conditionFor.entryList) && conditionFor.entryList.includes(conditionData.id))}
							isAvailable={isAvailable && !(conditionFor.for === 'endpointRun' && Array.isArray(conditionFor.entryList) && conditionFor.entryList.includes(conditionData.id))}
							changeFlg={changeFlg}
							setChangeFlg={setChangeFlg}
							conditionFor={conditionFor}
						/>
						{/* разделитель И */}
						{conditionDataBlock.length - 1 === conditionIdx ?
							isAvailable &&
							<button className={styles.conditionDataBlockLineButton} onClick={() => addConditionRunHandler(conditionBlockIdx)}>
								{translate('title_and')}
							</button>
							:
							<div className={styles.conditionDataBlockLine}>
								<span className={styles.conditionDataBlockLineTitle}>{translate('title_and')}</span>
							</div>
						}
					</Fragment>
				))}
			</fieldset>
			{/* разделитель ИЛИ */}
			{arrayConditionsData.length - 1 !== conditionBlockIdx &&
				<div className={styles.conditionDataBlockLine}>
					<span className={styles.conditionDataBlockLineTitle}>{translate('title_or').toUpperCase()}</span>
				</div>
			}
		</>
	);
};

export default memo(ConditionRunBlock);
