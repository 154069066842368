import { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectActiveType, selectFullModel } from '../../store/modelSlice';
import { clearCompressionPhrase, clearCorrect, clearEmotionAnalysis, clearManualCheckModelSmc, clearNormalization, clearPunctuate, clearToxicity, compressPhrase, correct, getEmotionAnalysis, getToxicityAnalysis, manualCheckModelSmc, normalize, punctuate, selectCompressionPhrase, selectEmotionAnalysis, selectErrorCorrection, selectManualCheckModelSmc, selectNormalization, selectPunctuate, selectToxicity } from '../../store/smcSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVER, SMC } from '../../constants/accessRights';
import { IToxicityResponse } from '../../types/smcTypes';
import { RequestStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IManualCheckProps } from './ManualCheck.props';
import styles from './ManualCheck.module.scss';

const ManualCheckSmc = ({ showManualCheck, withErrors = false, modelName, modelNameAsIs = false }: IManualCheckProps): JSX.Element => {
	const [manualCheckType, setManualCheckType] = useState<'classification' | 'compression' | 'other'>('classification'); // выбор ручной проверки
	const [inputText, setInputText] = useState<string>(''); // текст для проверки
	const [inputModelName, setInputModelName] = useState<string>(''); // имя продовой модели для расширенной проверки
	const [modelNamesProd, setModelNamesProd] = useState<string[]>([]); // список моделей из прода для расширенного режима проверки
	const [confidenceThreshold, setСonfidenceThreshold] = useState<number>(40); // порог доверия
	const [threshold, setThreshold] = useState<number>(30); // компрессия
	const inputTextRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода текста

	const dispatch = useAppDispatch();
	const fullModel = useAppSelector(selectFullModel); // store - информация о модели

	const [advancedMode, setAdvancedMode] = useState<boolean>(
		(modelName === null || fullModel.fullModel[fullModel.activeType] === null)
			? true : false
	); // флаг для расширенного режима

	const activeType = useAppSelector(selectActiveType); // store - активный тип модели
	const manualCheck = useAppSelector(selectManualCheckModelSmc); // store - результат ручной проверки smc
	const compressionPhrase = useAppSelector(selectCompressionPhrase); // store - результат сжатия фразы
	const emotionAnalysis = useAppSelector(selectEmotionAnalysis); // store - результат анализа эмоциональности
	const punctuation = useAppSelector(selectPunctuate); // store - результат пунктуации
	const errorCorrection = useAppSelector(selectErrorCorrection); // store - результат коррекции ошибок
	const normalization = useAppSelector(selectNormalization); // store - результат обратной нормализации
	const toxicity = useAppSelector(selectToxicity); // store - анализ на токсичность
	const clusterServer = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик ручной проверки модели
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если нажата кнопка классификации
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'classificationBtn') {
			manualCheck.data !== null && dispatch(clearManualCheckModelSmc()); // если есть данные ручной проверки - очищаем
			// если включен расширенный режим и выбрано имя модели
			if (advancedMode && inputModelName.length > 0) {
				dispatch(manualCheckModelSmc({ modelName: inputModelName, text: inputText, confidenceThreshold })); // ручная проверка
			} else {
				modelName && dispatch(manualCheckModelSmc({ modelName: (activeType === 'future' && !modelNameAsIs) ? `${modelName}-new` : modelName, text: inputText, confidenceThreshold })); // ручная проверка
			}
		}
		// если нажата кнопка сжатия
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'compressionBtn') {
			compressionPhrase.data !== null && dispatch(clearCompressionPhrase()); // если есть данные сжатия - очищаем
			dispatch(compressPhrase({ text: inputText, threshold })); // сжатие фразы
		}
		// если нажата кнопка анализа эмоциональности
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'emotionBtn') {
			emotionAnalysis.data !== null && dispatch(clearEmotionAnalysis()); // если есть данные анализа эмоциональности - очищаем
			toxicity.data !== null && dispatch(clearToxicity()); // если есть данные анализа токсичности - очищаем
			punctuation.data !== null && dispatch(clearPunctuate()); // если есть данные пунктуации - очищаем
			errorCorrection.data !== null && dispatch(clearCorrect()); // если есть данные коррекции ошибок - очищаем
			normalization.data !== null && dispatch(clearNormalization()); // если есть данные обратной нормализации - очищаем
			dispatch(getEmotionAnalysis({ text: inputText })); // анализ эмоциональности
		}
		// если нажата кнопка анализа на токсичность
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'toxicityBtn') {
			emotionAnalysis.data !== null && dispatch(clearEmotionAnalysis()); // если есть данные анализа эмоциональности - очищаем
			toxicity.data !== null && dispatch(clearToxicity()); // если есть данные анализа токсичности - очищаем
			punctuation.data !== null && dispatch(clearPunctuate()); // если есть данные пунктуации - очищаем
			errorCorrection.data !== null && dispatch(clearCorrect()); // если есть данные коррекции ошибок - очищаем
			normalization.data !== null && dispatch(clearNormalization()); // если есть данные обратной нормализации - очищаем
			dispatch(getToxicityAnalysis(inputText)); // анализ на токсичность
		}
		// если нажата кнопка пунктуации
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'punctuateBtn') {
			emotionAnalysis.data !== null && dispatch(clearEmotionAnalysis()); // если есть данные анализа эмоциональности - очищаем
			toxicity.data !== null && dispatch(clearToxicity()); // если есть данные анализа токсичности - очищаем
			punctuation.data !== null && dispatch(clearPunctuate()); // если есть данные пунктуации - очищаем
			errorCorrection.data !== null && dispatch(clearCorrect()); // если есть данные коррекции ошибок - очищаем
			normalization.data !== null && dispatch(clearNormalization()); // если есть данные обратной нормализации - очищаем
			dispatch(punctuate({ text: inputText })); // пунктуация
		}
		// если нажата кнопка коррекции ошибок
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'errorCorrectionBtn') {
			emotionAnalysis.data !== null && dispatch(clearEmotionAnalysis()); // если есть данные анализа эмоциональности - очищаем
			toxicity.data !== null && dispatch(clearToxicity()); // если есть данные анализа токсичности - очищаем
			punctuation.data !== null && dispatch(clearPunctuate()); // если есть данные пунктуации - очищаем
			errorCorrection.data !== null && dispatch(clearCorrect()); // если есть данные коррекции ошибок - очищаем
			normalization.data !== null && dispatch(clearNormalization()); // если есть данные обратной нормализации - очищаем
			dispatch(correct({ text: inputText })); // коррекция ошибок
		}
		// если нажата кнопка обратной нормализации
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'normalizationBtn') {
			emotionAnalysis.data !== null && dispatch(clearEmotionAnalysis()); // если есть данные анализа эмоциональности - очищаем
			toxicity.data !== null && dispatch(clearToxicity()); // если есть данные анализа токсичности - очищаем
			punctuation.data !== null && dispatch(clearPunctuate()); // если есть данные пунктуации - очищаем
			errorCorrection.data !== null && dispatch(clearCorrect()); // если есть данные коррекции ошибок - очищаем
			normalization.data !== null && dispatch(clearNormalization()); // если есть данные обратной нормализации - очищаем
			dispatch(normalize({ text: inputText })); // обратная нормализация
		}
	};

	// следим за расширенным режимом
	useEffect(() => {
		// если расширенный режим включен - получаем продовые модели с сервера cluster
		if (advancedMode) {
			dispatch(getClusterServerModels({ serviceType: 'smc' }));
		} else {
			setInputModelName(''); // очищаем имя продовой модели
			manualCheck.data !== null && dispatch(clearManualCheckModelSmc()); // если есть реультат проверки - очищаем
			(clusterServer.data !== null || clusterServer.status === RequestStatus.FAILED) && dispatch(clearClusterServer()); // если есть список продовых моделей или не удалось их получить - очищаем
		}
	}, [advancedMode]);

	// следим за статусом получения продовых моделей с серверов cluster
	useEffect(() => {
		// если нет ошибок и есть данные
		if (clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					if (!/-new$/m.test(modelName)) {
						!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // оставляем все уникальные модели без постфикса -new
					}
				});
			});
			setModelNamesProd(arrModelNames); // пишем список моделей в state
		}
	}, [clusterServer.status]);

	// следим за сменой табов
	useEffect(() => {
		inputText.length > 0 && setInputText(''); // если был написан текст - очищаем
		modelName && advancedMode === true && setAdvancedMode(false); // если был выбран расширенный режим - выключаем
		!modelName && advancedMode === false && setAdvancedMode(true); // если был убран расширенный режим - включаем
		confidenceThreshold !== 40 && setСonfidenceThreshold(40); // если был изменен порог доверия - сбрасываем
		threshold !== 30 && setThreshold(30); // если была изменена компрессия - сбрасываем
		(manualCheck.data !== null || manualCheck.status === RequestStatus.FAILED) && dispatch(clearManualCheckModelSmc()); // если есть данные проверки или статус "Ошибка" - очищаем данные проверки
		(clusterServer.data !== null || clusterServer.status === RequestStatus.FAILED) && dispatch(clearClusterServer()); // если есть данные по продовым моделям серверов cluster или статус "Ошибка" - очищаем данные моделей
		(compressionPhrase.data !== null || manualCheck.status === RequestStatus.FAILED) && dispatch(clearCompressionPhrase()); // если есть данные сжатия фразы или статус "Ошибка" - очищаем данные сжатия
		(emotionAnalysis.data !== null || emotionAnalysis.status === RequestStatus.FAILED) && dispatch(clearEmotionAnalysis()); // если есть данные анализа эмоциональности - очищаем данные сжатия
		(punctuation.data !== null || punctuation.status === RequestStatus.FAILED) && dispatch(clearPunctuate()); // если есть данные пунктуации - очищаем
		(errorCorrection.data !== null || errorCorrection.status === RequestStatus.FAILED) && dispatch(clearCorrect()); // если есть данные коррекции ошибок - очищаем
		(normalization.data !== null || normalization.status === RequestStatus.FAILED) && dispatch(clearNormalization()); // если есть данные обратной нормализации - очищаем
		(toxicity.data !== null || toxicity.status === RequestStatus.FAILED) && dispatch(clearToxicity()); // если есть данные анализа токсичности - очищаем
	}, [manualCheckType]);

	return (
		<Slide direction="left" in={showManualCheck} mountOnEnter unmountOnExit>
			<div className={cn(styles.manualCheck, {
				[styles.manualCheckWithErrors]: withErrors
			})} onMouseDown={(e) => e.stopPropagation()}
			>
				<ButtonGroup fullWidth sx={{ marginBottom: '8px' }}>
					{isAccess(SMC.CLASSIFY) && <Button
						variant={manualCheckType === 'classification' ? "contained" : "outlined"}
						sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
						onClick={() => setManualCheckType('classification')}>
						{translate('button_classification')}
					</Button>}
					{isAccess(SMC.COMPRESS) && <Button
						variant={manualCheckType === 'compression' ? "contained" : "outlined"}
						sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
						onClick={() => setManualCheckType('compression')}>
						{translate('button_compression')}
					</Button>}
					{(isAccess(SMC.EMOTION) || isAccess(SMC.PUNCTUATE) || isAccess(SMC.CORRECT) || isAccess(SMC.NORMALIZE)) && <Button
						variant={manualCheckType === 'other' ? "contained" : "outlined"}
						sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
						onClick={() => setManualCheckType('other')}>
						{translate('button_other')}
					</Button>}
				</ButtonGroup>

				<form className={styles.manualCheckForm} onSubmit={e => submitHandler(e)}>
					<FormControl fullWidth margin='dense'>
						<TextField
							autoFocus
							inputRef={inputTextRef}
							required
							id="text"
							label={translate('input_text')}
							variant="outlined"
							multiline
							rows={6}
							value={inputText}
							onChange={(e) => setInputText(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" >
										{inputText.length > 0 && <FontAwesomeIcon icon={faXmark} onClick={() => { setInputText(''); inputTextRef.current?.focus(); }} style={{ cursor: 'pointer ' }} />}
									</InputAdornment>
								),
								style: {
									fontSize: 13,
									padding: 8
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>

					{manualCheckType === 'classification' && <>
						<FormControl fullWidth margin='dense'>
							<TextField
								id="confidenceThreshold"
								label={translate("input_confidenceThreshold")}
								variant="outlined"
								type='number'
								value={confidenceThreshold}
								onChange={(e) => setСonfidenceThreshold(Number(e.target.value))}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
									},
									inputProps: { min: 0, max: 100 }
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						{isAccess(SERVER.MODEL_LIST) &&
							<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={advancedMode}
									disabled={modelName === null}
									onChange={e => setAdvancedMode(e.target.checked)}
									size='small'
								/>
							} label={translate('checkbox_advancedMode')} />
						}
						{advancedMode &&
							<>
								{clusterServer.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} inLine /></div>}
								{clusterServer.status === RequestStatus.FAILED && <p className={styles.failedText}>{translate('title_functionNotAvailable')}</p>}
								{clusterServer.status === RequestStatus.IDLE &&
									<>
										{modelNamesProd.length > 0 ?
											<FormControl fullWidth sx={{ marginBottom: '8px', '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
												<InputLabel id="modelName-label" sx={{ fontSize: 13 }}>{translate('select_model')}</InputLabel>
												<Select
													required
													labelId="modelName-label"
													id="modelName"
													label={translate('select_model')}
													value={inputModelName}
													onChange={(e) => setInputModelName(e.target.value)}
													style={{ fontSize: 13, height: 33 }}
												>
													{modelNamesProd.map((modelName) => (
														<MenuItem key={modelName} value={modelName} sx={{ fontSize: 13 }}>{modelName}</MenuItem>
													))}
												</Select>
											</FormControl>
											:
											<p className={styles.failedText}>{translate('title_noData')}</p>
										}
									</>
								}
							</>
						}
						<FormControl fullWidth>
							{manualCheck.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_classification')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button variant="outlined" sx={{ fontSize: 11 }} type='submit' id='classificationBtn'>{translate('button_classification')}</Button>
							}
						</FormControl>
					</>}

					{manualCheckType === 'other' && <>
						{isAccess(SMC.EMOTION) && <FormControl fullWidth margin='dense'>
							{emotionAnalysis.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_analysis')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='submit'
									id='emotionBtn'
									disabled={punctuation.status === RequestStatus.LOADING || errorCorrection.status === RequestStatus.LOADING || normalization.status === RequestStatus.LOADING || toxicity.status === RequestStatus.LOADING}
								>
									{translate('button_emotionAnalysis')}
								</Button>
							}
						</FormControl>}
						{isAccess(SMC.TOXICITY) && <FormControl fullWidth margin='dense'>
							{toxicity.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_analysis')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='submit'
									id='toxicityBtn'
									disabled={emotionAnalysis.status === RequestStatus.LOADING || punctuation.status === RequestStatus.LOADING || errorCorrection.status === RequestStatus.LOADING || normalization.status === RequestStatus.LOADING}
								>
									{translate('button_toxicityAnalysis')}
								</Button>
							}
						</FormControl>}
						{isAccess(SMC.PUNCTUATE) && <FormControl fullWidth margin='dense'>
							{punctuation.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_punctuation')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='submit'
									id='punctuateBtn'
									disabled={emotionAnalysis.status === RequestStatus.LOADING || errorCorrection.status === RequestStatus.LOADING || normalization.status === RequestStatus.LOADING || toxicity.status === RequestStatus.LOADING}
								>
									{translate('button_punctuation')}
								</Button>
							}
						</FormControl>}
						{isAccess(SMC.CORRECT) && <FormControl fullWidth margin='dense'>
							{errorCorrection.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_errorEorrection')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='submit'
									id='errorCorrectionBtn'
									disabled={emotionAnalysis.status === RequestStatus.LOADING || punctuation.status === RequestStatus.LOADING || normalization.status === RequestStatus.LOADING || toxicity.status === RequestStatus.LOADING}
								>
									{translate('button_errorEorrection')}
								</Button>
							}
						</FormControl>}
						{isAccess(SMC.NORMALIZE) && <FormControl fullWidth margin='dense'>
							{normalization.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_normalization')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='submit'
									id='normalizationBtn'
									disabled={emotionAnalysis.status === RequestStatus.LOADING || punctuation.status === RequestStatus.LOADING || errorCorrection.status === RequestStatus.LOADING || toxicity.status === RequestStatus.LOADING}
								>
									{translate('button_normalization')}
								</Button>
							}
						</FormControl>}
					</>}

					{manualCheckType === 'compression' && <>
						<FormControl fullWidth margin='dense'>
							<TextField
								id="threshold"
								label={translate("input_compressionThreshold")}
								variant="outlined"
								type='number'
								value={threshold}
								onChange={(e) => setThreshold(Number(e.target.value))}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13
									},
									inputProps: { min: 0, max: 100 }
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						<FormControl fullWidth margin='dense'>
							{compressionPhrase.status === RequestStatus.LOADING ?
								<Button variant="outlined" disabled sx={{ fontSize: 11 }}>
									{translate('button_compress')}
									<ProgressCircle isBtnDisabled />
								</Button>
								:
								<Button variant="outlined" sx={{ fontSize: 11 }} type='submit' id='compressionBtn'>{translate('button_compress')}</Button>
							}
						</FormControl>
					</>}
				</form>

				<div className={styles.manualCheckData}>
					{manualCheck.status === RequestStatus.LOADING &&
						<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_search')} /></div>
					}
					{manualCheck.data === null && manualCheck.status === RequestStatus.FAILED &&
						<p className={styles.failedText}>{translate('title_loadFailed')}</p>
					}
					{manualCheck.data !== null && typeof manualCheck.data === 'object' && 'message' in manualCheck.data &&
						<p className={styles.notFoundText}>{translate(manualCheck.data.message || 'title_loadFailed')}</p>
					}

					{compressionPhrase.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_phraseСompression')} /></div>}
					{compressionPhrase.status === RequestStatus.FAILED && compressionPhrase.data === null && <p className={styles.failedText}>{translate('title_loadFailed')}</p>}
					{typeof compressionPhrase.data === 'string' && <p className={styles.internalError}>{translate('title_loadFailed')}</p>}

					{emotionAnalysis.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_analysis')} /></div>}
					{emotionAnalysis.status === RequestStatus.FAILED && emotionAnalysis.data === null && <p className={styles.failedText}>{translate('title_loadFailed')}</p>}
					{typeof emotionAnalysis.data === 'string' && <p className={styles.internalError}>{translate('title_loadFailed')}</p>}

					{toxicity.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_analysis')} /></div>}
					{toxicity.status === RequestStatus.FAILED && <p className={styles.failedText}>{translate(toxicity.message || 'title_loadFailed')}</p>}

					{punctuation.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_punctuation')} /></div>}
					{punctuation.status === RequestStatus.FAILED && punctuation.data === null && <p className={styles.failedText}>{translate('title_loadFailed')}</p>}
					{typeof punctuation.data === 'string' && <p className={styles.internalError}>{translate('title_loadFailed')}</p>}

					{errorCorrection.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_errorСorrection')} /></div>}
					{errorCorrection.status === RequestStatus.FAILED && errorCorrection.data === null && <p className={styles.failedText}>{translate('title_loadFailed')}</p>}
					{typeof errorCorrection.data === 'string' && <p className={styles.internalError}>{translate('title_loadFailed')}</p>}

					{normalization.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_normalization')} /></div>}
					{normalization.status === RequestStatus.FAILED && normalization.data === null && <p className={styles.failedText}>{translate('title_loadFailed')}</p>}
					{typeof normalization.data === 'string' && <p className={styles.internalError}>{translate('title_loadFailed')}</p>}

					{manualCheck.data !== null && typeof manualCheck.data === 'object' && 'classes' in manualCheck.data &&
						<>
							<div>
								<div className={styles.manualCheckDataPair}>
									<p><span className={styles.manualCheckDataKey}>{translate('title_classes')}:</span> {manualCheck.data.classes.length === 0 && translate('title_empty').toLowerCase()}</p>
									{manualCheck.data.classes.map((classItem, idx) =>
										<div key={idx} className={styles.manualCheckDataPairBlock}>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_class')}:</span> {classItem.class}
											</p>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_confidence')}:</span> {classItem.confidence}
											</p>
										</div>
									)}
								</div>
							</div>

							<div>
								<div className={styles.manualCheckDataPair}>
									<p><span className={styles.manualCheckDataKey}>{translate('title_groups')}:</span> {manualCheck.data.groups.length === 0 && translate('title_empty').toLowerCase()}</p>
									{manualCheck.data.groups.map((groupItem, idx) =>
										<div key={idx} className={styles.manualCheckDataPairBlock}>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_group')}:</span> {groupItem.group}
											</p>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_quantityMatchingClasses')}:</span> {groupItem.intersection}
											</p>
										</div>
									)}
								</div>
							</div>

							<div>
								<div className={styles.manualCheckDataPair}>
									<p><span className={styles.manualCheckDataKey}>{translate('title_nearestGroups')}:</span> {manualCheck.data.nearest.length === 0 && translate('title_empty').toLowerCase()}</p>
									{manualCheck.data.nearest.map((nearestItem, idx) =>
										<div key={idx} className={styles.manualCheckDataPairBlock}>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_group')}:</span> {nearestItem.group}
											</p>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_percentageOfFullness')}:</span> {nearestItem.percent}
											</p>
											<p className={styles.manualCheckDataPairInner}>
												<span className={styles.manualCheckDataPairInnerKey}>{translate('title_need')}:</span> {nearestItem.need}
											</p>
										</div>
									)}
								</div>
							</div>
						</>
					}

					{compressionPhrase.data && typeof compressionPhrase.data !== 'string' && <>
						{'error' in compressionPhrase.data ?
							<p className={styles.notFoundText}>{translate(compressionPhrase.data.message || 'title_loadFailed')}</p>
							:
							<>
								{compressionPhrase.data.text ?
									<p>{compressionPhrase.data.text}</p>
									:
									<p className={styles.notFoundText}>{translate('title_notFound')}</p>
								}
							</>
						}
					</>}

					{/* результат анализа на токсичность */}
					{toxicity.data && <>
						<div>
							{Object.keys(toxicity.data).map(key => (
								<div className={styles.manualCheckDataPair} key={key}>
									<><span className={styles.manualCheckDataKey}>{translate(key)}:</span> {toxicity.data && toxicity.data[key as keyof IToxicityResponse]}</>
								</div>
							))}
						</div>
					</>}

					{emotionAnalysis.data && typeof emotionAnalysis.data !== 'string' && <>
						{'error' in emotionAnalysis.data ?
							<p className={styles.notFoundText}>{translate(emotionAnalysis.data.message || 'title_loadFailed')}</p>
							:
							<div>
								<div className={styles.manualCheckDataPair}>
									<><span className={styles.manualCheckDataKey}>{translate('title_emotion')}:</span> {translate(emotionAnalysis.data.emotion)}</>
								</div>
								<div className={styles.manualCheckDataPair}>
									<><span className={styles.manualCheckDataKey}>{translate('title_level')}:</span> {emotionAnalysis.data.score.toFixed(3)}</>
								</div>
							</div>
						}
					</>}

					{punctuation.data && typeof punctuation.data !== 'string' && <>
						{'error' in punctuation.data ?
							<p className={styles.notFoundText}>{translate(punctuation.data.message || 'title_loadFailed')}</p>
							:
							<div className={styles.manualCheckDataPair}>{translate(punctuation.data.text)}</div>
						}
					</>}

					{errorCorrection.data && typeof errorCorrection.data !== 'string' && <>
						{'error' in errorCorrection.data ?
							<p className={styles.notFoundText}>{translate(errorCorrection.data.message || 'title_loadFailed')}</p>
							:
							<div className={styles.manualCheckDataPair}>{translate(errorCorrection.data.text)}</div>
						}
					</>}

					{normalization.data && typeof normalization.data !== 'string' && <>
						{'error' in normalization.data ?
							<p className={styles.notFoundText}>{translate(normalization.data.message || 'title_loadFailed')}</p>
							:
							<div className={styles.manualCheckDataPair}>{translate(normalization.data.text)}</div>
						}
					</>}
				</div>
			</div>
		</Slide>
	);
};

export default ManualCheckSmc;
