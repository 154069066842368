import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addActiveQuestionId, clearAnswerMakerStatus, clearProcessingQuestionsStatus, clearQuestion, clearState, getCategoriesList, getChannelList, getQuestion, getQuestionsList, getStatusGenerateAnswers, getStatusProcessingQuestions, selectCategoriesList, selectChannelList, selectFilter, selectGeneratingAnswersProcessStatus, selectProcessingQuestionsProcessStatus, selectQuestion, selectQuestionsList } from '../../store/qasSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS, SERVER, SERVICE } from '../../constants/accessRights';
import { QAS_QUESTION_ID } from '../../constants/cookieNames';
import { IQuestionItem } from '../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import QuestionNavbar from '../../components/Navbars/QuestionNavbar/QuestionNavbar';
import QuestionControls from '../../components/Controls/QuestionControls/QuestionControls';
import AnswerToQuestion from '../../components/AnswerToQuestion/AnswerToQuestion';
import Answers from '../../components/Answers/Answers';
import NoticeSingleAction from '../../components/Notification/NoticeSingleAction/NoticeSingleAction';
import { IQuestionProps } from './Question.props';
import styles from './Question.module.scss';

const Question = ({ serviceType }: IQuestionProps): JSX.Element => {
	const [inputQuestion, setInputQuestion] = useState<string>(''); // текст вопроса
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [incompleteQuestionFlg, setIncompleteQuestionFlg] = useState<boolean>(false); // флаг неполного вопроса
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении ответов на вопрос и возможности сохранить эти изменения
	const [promiseGetQuestion, setPromiseGetQuestion] = useState<Promise<any> | null>(null); // промис для отмены запроса получения вопроса
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели
	const [showNotificationCategory, setShowNotificationCategory] = useState<boolean>(false); // показ уведомлений статуса загрузки списка категорий
	const [showNotificationChannel, setShowNotificationChannel] = useState<boolean>(false); // показ уведомлений статуса загрузки списка каналов
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления вопроса

	const [highlightMultipleQuestions, setHighlightMultipleQuestions] = useState<boolean>(false); // флаг выделения нескольких вопросов
	const [selectedListQuestionIds, setSelectedListQuestionIds] = useState<string[]>([]); // список id выделенных вопросов

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const question = useAppSelector(selectQuestion); // store - вопрос/ответ
	const processGenerateAnswersStatus = useAppSelector(selectGeneratingAnswersProcessStatus); // store - статус процесса генерации ответов
	const processingQuestionsProcessStatus = useAppSelector(selectProcessingQuestionsProcessStatus); // store - статус массовой обработки вопросов

	const [_cookies, setCookie] = useCookies([QAS_QUESTION_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(QAS.QUESTION_LIST) && dispatch(getQuestionsList({ answer: filter.answer, question: filter.question })); //получаем список вопросов
		isAccess(QAS.CATEGORY_LIST) && dispatch(getCategoriesList()); // получаем список категорий
		isAccess(QAS.CHANNEL_LIST) && dispatch(getChannelList()); // получаем список каналов
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очистка всего state
		};
	}, []);

	// следим за статусом процесса генерации ответов
	useEffect(() => {
		// автозапрос статуса процесса генерации ответов каждые 5 сек
		const interval = setInterval(() => {
			isAccess([QAS.ANSWER_MAKER_START, QAS.ANSWER_MAKER_STOP, QAS.ANSWER_MAKER_STATUS]) && dispatch(getStatusGenerateAnswers());
		}, 5000);

		// если остановлена генерация
		if (processGenerateAnswersStatus.responseStatus === 'stopped') {
			isAccess([QAS.ANSWER_MAKER_START, QAS.ANSWER_MAKER_STOP, QAS.ANSWER_MAKER_STATUS]) && dispatch(getStatusGenerateAnswers()); // получаем статус процесса генерации ответов
			clearInterval(interval); // удаляем автозапрос
			dispatch(clearAnswerMakerStatus()); // очищаем данные
		}

		return () => {
			clearInterval(interval); // удаляем автозапрос получения статуса процесса генерации ответов
		};
	}, [processGenerateAnswersStatus.responseStatus]);

	// следим за статусом массовой обработки вопросов
	useEffect(() => {
		// автозапрос статуса массовой обработки вопросов каждые 5 сек
		const interval = setInterval(() => {
			isAccess([QAS.PROCESSING_QUESTIONS_START, QAS.PROCESSING_QUESTIONS_STOP, QAS.PROCESSING_QUESTIONS_STATUS]) && dispatch(getStatusProcessingQuestions());
		}, 5000);

		// если остановлена обработка
		if (processingQuestionsProcessStatus.responseStatus === 'stopped') {
			isAccess([QAS.PROCESSING_QUESTIONS_START, QAS.PROCESSING_QUESTIONS_STOP, QAS.PROCESSING_QUESTIONS_STATUS]) && dispatch(getStatusProcessingQuestions()); // получаем статус процесса массовой обработки вопросов
			clearInterval(interval); // удаляем автозапрос
			dispatch(clearProcessingQuestionsStatus()); // очищаем данные
		}

		return () => {
			clearInterval(interval); // удаляем автозапрос получения статуса процесса массовой обработки вопросов
		};
	}, [processingQuestionsProcessStatus.responseStatus]);

	// следим за статусом получения списка категорий и в случае ошибок - включаем уведомление
	useEffect(() => {
		if (categoriesList.status === RequestStatus.FAILED || categoriesList.error === ResponseStatus.FAILED) setShowNotificationCategory(true);
	}, [categoriesList.status]);

	// следим за статусом получения списка каналов и в случае ошибок - включаем уведомление
	useEffect(() => {
		if (channelList.status === RequestStatus.FAILED || channelList.error === ResponseStatus.FAILED) setShowNotificationChannel(true);
	}, [channelList.status]);

	// следим за статусом получения вопроса
	useEffect(() => {
		// если закончилась загрузка вопроса - сбрасываем промис для отмены запроса
		question.status !== RequestStatus.LOADING && setPromiseGetQuestion(null);
		// если ошибка (случай с отменой запроса) - запрашиваем другой вопрос
		question.status === RequestStatus.FAILED && question.questionId && questionHandler(question.questionId);
	}, [question.status]);

	// следим за флагом выделения нескольких вопросов
	useEffect(() => {
		// если флаг выключен и остались выделенные вопросы - сбрасываем
		!highlightMultipleQuestions && selectedListQuestionIds.length > 0 && setSelectedListQuestionIds([]);
	}, [highlightMultipleQuestions]);

	// обработчик выбора активного вопроса
	const questionHandler = (questionId: string): void => {
		question.questionId && dispatch(clearQuestion()); // очищаем все данные о вопросе, если есть
		dispatch(addActiveQuestionId(questionId)); // добавляем id активного вопроса в store
		// если сохранен промис для отмены запроса
		if (promiseGetQuestion) {
			// @ts-ignore
			promiseGetQuestion.abort(); // прерываем запрос
			setPromiseGetQuestion(null); // обнуляем промис
			return;
		}
		setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
		const promise = dispatch(getQuestion(questionId)); // получаем данные вопроса
		setPromiseGetQuestion(promise); // устанавливаем промис для случая с отменой запроса
		setCookie(QAS_QUESTION_ID, questionId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// функция фильтрации списка вопросов
	const filterFunction = (questionItem: IQuestionItem): boolean => {
		return (questionItem.category === filter.category || filter.category === '') &&
			((filter.answerType === 'all' && (questionItem.checked >= 0 || questionItem.unchecked >= 0)) ||
				(filter.answerType === 'checked' && questionItem.checked > 0) ||
				(filter.answerType === 'unchecked' && questionItem.unchecked > 0) ||
				(filter.answerType === 'empty' && (questionItem.checked === 0 && questionItem.unchecked === 0))
			);
	};

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.QAS, QAS.QUESTION_LIST]}>
			<>
				<QuestionControls
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
					inputQuestion={inputQuestion}
					selectCategoryId={selectCategoryId}
					incompleteQuestionFlg={incompleteQuestionFlg}
					showAlertDialogDelete={showAlertDialogDelete}
					setShowAlertDialogDelete={setShowAlertDialogDelete}
					highlightMultipleQuestions={highlightMultipleQuestions}
					setHighlightMultipleQuestions={setHighlightMultipleQuestions}
					selectedListQuestionIds={selectedListQuestionIds}
				/>

				<div className={styles.bottomContainer}>
					<QuestionNavbar
						questionHandler={questionHandler}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setShowPage={setShowPage}
						filter={filter}
						highlightMultipleQuestions={highlightMultipleQuestions}
						selectedListQuestionIds={selectedListQuestionIds}
						setSelectedListQuestionIds={setSelectedListQuestionIds}
					/>

					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{questionsList.data.filter(filterFunction).length > 0 ?
							<Answers
								changeFlg={changeFlg}
								setChangeFlg={setChangeFlg}
								inputQuestion={inputQuestion}
								setInputQuestion={setInputQuestion}
								selectCategoryId={selectCategoryId}
								setSelectCategoryId={setSelectCategoryId}
								incompleteQuestionFlg={incompleteQuestionFlg}
								setIncompleteQuestionFlg={setIncompleteQuestionFlg}
								setShowAlertDialogDelete={setShowAlertDialogDelete}
							/>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}

						<div className={styles.wrapperQuestion}>
							<AnswerToQuestion changeFlg={changeFlg} />
						</div>
					</div>
				</div>

				{showNotificationCategory &&
					<NoticeSingleAction
						showNotification={showNotificationCategory}
						setShowNotification={setShowNotificationCategory}
						title={translate(categoriesList.message || 'noticeLoadingCategories_failed')}
					/>
				}
				{showNotificationChannel &&
					<NoticeSingleAction
						showNotification={showNotificationChannel}
						setShowNotification={setShowNotificationChannel}
						title={translate(channelList.message || 'noticeLoadingChannels_failed')}
					/>
				}
			</>
		</PageWrapper>
	);
};

export default Question;
