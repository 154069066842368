import { memo, useEffect, useMemo, useState } from 'react';
import { Button, Fade, FormControl, MenuItem, Select } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addAnswer, addChannelsIdAndCountAnswers, clearEditingQuestion, editQuestion, selectChannelList, selectEditingQuestion, selectFilter, selectQuestion, selectQuestionsList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import QuestionParameters from '../QuestionParameters/QuestionParameters';
import Answer from './Answer/Answer';
import AlertDialog from '../AlertDialog/AlertDialog';
import Notification from '../Notification/Notification';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IAnswersProps } from './Answers.props';
import styles from './Answers.module.scss';

const Answers = ({ changeFlg, setChangeFlg, inputQuestion, setInputQuestion, selectCategoryId, setSelectCategoryId, incompleteQuestionFlg, setIncompleteQuestionFlg, setShowAlertDialogDelete }: IAnswersProps): JSX.Element => {
	const [selectChannelId, setSelectChannelId] = useState<string>('0'); // id канала для фильтрации ответов
	const [showQuestionParams, setShowQuestionParams] = useState<boolean>(false); // показ вкладки доп.параметров вопросов
	const [expandedQuestionText, setExpandedQuestionText] = useState<boolean>(false); // показ развернутого текста вопроса

	const [showAlertDialogSave, setShowAlertDialogSave] = useState<boolean>(false); // показ диалогового окна для сохранения
	const [showNotificationSave, setShowNotificationSave] = useState<boolean>(false); // показ уведомления сохранения

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const question = useAppSelector(selectQuestion); // store - вопрос/ответ
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// поиск вопроса в списке вопросов
	const foundQuestion = useMemo(() => {
		return questionsList.data.find(questionItem => questionItem.id === question.questionId);
	}, [question.questionId, question.aliases]);

	// следим за вопросом
	useEffect(() => {
		if (foundQuestion) {
			if (filter.answer !== '') setSelectChannelId('notSelected'); // если есть фильтр по ответу - ставим пункт с фильтром
			else setSelectChannelId('0'); // иначе сбрасывем фильтр ответов на по умолчанию
		}
	}, [foundQuestion]);

	// следим за ответами
	useEffect(() => {
		question.answers.length > 0 && counterAnswers(); // если есть список - считаем количество ответов
		setExpandedQuestionText(false); // убираем флаг развернутого вопроса
	}, [question.answers]);

	// подсчет ответов для каждого из каналов
	const counterAnswers = () => {
		const channelsAndCountAnswers: Record<string, number> = {};  // список каналов и количество ответов

		// перебираем ответы
		question.answers.forEach(({ channel }) => {
			// если в списке каналов содержится текущий канал - прибавляем количество
			if (channelsAndCountAnswers.hasOwnProperty(channel)) channelsAndCountAnswers[channel]++;
			else channelsAndCountAnswers[channel] = 1; // иначе начинаем счет
		});

		dispatch(addChannelsIdAndCountAnswers(channelsAndCountAnswers)); // добавляем id каналов и количество ответов
	};

	// обработчик добавления ответов
	const addAnswersHandler = (): void => {
		// если нет последнего ответа без текста
		if (question.answers[question.answers.length - 1].answer !== '') {
			dispatch(addAnswer(selectChannelId));
			setChangeFlg(true);
		}
	};

	// обработчик сохранения изменений
	const saveHandler = (): void => {
		setShowAlertDialogSave(false); // закрываем диалоговое окно
		setShowNotificationSave(true); // включаем уведомление
		question.questionId && dispatch(editQuestion({
			questionId: question.questionId,
			answers: question.answers,
			question: inputQuestion,
			aliases: question.aliases,
			candidates: question.candidates,
			categoryId: selectCategoryId,
			incomplete: incompleteQuestionFlg,
		})); // изменение вопросов и ответов
	};

	return (
		<Fade in={true} timeout={500}>
			<div className={styles.container}>
				{/* загрузка ответов */}
				{question.status === RequestStatus.LOADING &&
					<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
				}

				{/* ошибка загрузки ответов */}
				{(question.status === RequestStatus.FAILED || question.error === ResponseStatus.FAILED) &&
					<p className={styles.failedText}>
						{translate(question.message || 'title_loadFailed')}
					</p>
				}

				{/* ответы */}
				{question.status === RequestStatus.IDLE && question.answers.length > 0 &&
					<Fade in={true} timeout={500}>
						<div className={styles.answersWrapper}>
							<div className={styles.answersTopBlock}>
								<h3 className={cn(styles.answersQuestion, {
									[styles.answersQuestionExpanded]: expandedQuestionText,
								})}
									title={translate(expandedQuestionText ? 'title_collapse' : 'title_expand')}
									onClick={() => setExpandedQuestionText(prev => !prev)}
								>
									{foundQuestion?.question}
								</h3>
								<FormControl sx={{
									'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
									'.MuiInputBase-input': { padding: '0 10px' },
								}}>
									<Select
										id="channel"
										variant='standard'
										value={selectChannelId}
										onChange={(e) => setSelectChannelId(e.target.value)}
										style={{ fontSize: 13, height: 20, color: colorPrimary, }}
									>
										{/* если есть фильтр по ответу */}
										{filter.answer !== '' &&
											<MenuItem value='notSelected' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_notSelected')}</MenuItem>
										}
										{channelList.data.length > 0 ?
											channelList.data.map((channel) => (
												<MenuItem key={channel.id} value={channel.id} sx={{ fontSize: 13, color: colorPrimary }}>
													{channel.name} {question.channelsIdAndCountAnswers.hasOwnProperty(channel.id) && `(${question.channelsIdAndCountAnswers[channel.id]})`}
												</MenuItem>
											))
											:
											question.answers.map(answerItem =>
												<MenuItem key={answerItem.channel} value={answerItem.channel} sx={{ fontSize: 13, color: colorPrimary }}>{answerItem.channel} ({translate('selectItem_notFound')})</MenuItem>
											)
										}
									</Select>
								</FormControl>
							</div>

							<div className={styles.answersList}>
								{question.answers.map((answer, idx, answersArray) => {
									if (answer.channel === selectChannelId || (selectChannelId === 'notSelected' && answer.answer.toLowerCase().includes(filter.answer.toLowerCase()))) {
										return (
											<Answer checked={answer.checked} answer={answer.answer} channelId={answer.channel} setChangeFlg={setChangeFlg} idx={idx} answersArray={answersArray} filterByAnswer={filter.answer} selectChannelId={selectChannelId} key={answer.answer + idx} />
										);
									} else return undefined;
								})}

								{question.answers.filter(({ channel }) => channel === selectChannelId || selectChannelId === 'notSelected').length === 0 &&
									<div className={styles.answersListEmpty}>{translate('title_notFound')}</div>
								}
							</div>

							<div className={styles.answersBottomBlock}>
								<div className={styles.answersBottomBlockParams} onClick={() => setShowQuestionParams(true)}>
									{translate('link_additionally')}
								</div>
								<div className={styles.answersBottomBlockBtns}>
									{isAccess(QAS.QUESTION_EDIT) && changeFlg &&
										<Button
											variant="outlined"
											sx={{ fontSize: 11 }}
											onClick={() => setShowAlertDialogSave(true)}
										>
											{translate('button_save')}
										</Button>
									}
									{isAccess(QAS.QUESTION_EDIT) &&
										<Button
											id='addAnswer'
											variant="outlined"
											disabled={selectChannelId === 'notSelected'}
											sx={{ fontSize: 11 }}
											onClick={addAnswersHandler}
										>
											{translate('button_addAnswer')}
										</Button>
									}
								</div>
							</div>
						</div>
					</Fade>
				}

				<QuestionParameters
					showQuestionParams={showQuestionParams}
					setShowQuestionParams={setShowQuestionParams}
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					inputQuestion={inputQuestion}
					setInputQuestion={setInputQuestion}
					selectCategoryId={selectCategoryId}
					setSelectCategoryId={setSelectCategoryId}
					incompleteQuestionFlg={incompleteQuestionFlg}
					setIncompleteQuestionFlg={setIncompleteQuestionFlg}
					foundQuestion={foundQuestion}
					filterByQuestion={filter.question}
					setShowAlertDialogSave={setShowAlertDialogSave}
					setShowAlertDialogDelete={setShowAlertDialogDelete}
				/>

				{showAlertDialogSave &&
					<AlertDialog
						showAlertDialog={showAlertDialogSave}
						setShowAlertDialog={setShowAlertDialogSave}
						submitHandler={saveHandler}
						title='dialog_saveQuestion'
						description='dialog_saveQuestionConfirm'
					/>
				}

				{/* уведомление для сохранения вопроса */}
				{showNotificationSave &&
					<Notification
						showNotification={showNotificationSave}
						setShowNotification={setShowNotificationSave}
						selectDataResponse={selectEditingQuestion}
						clearDataResponse={clearEditingQuestion}
						titleFailed='noticeSaving_failed'
						titleSuccess='noticeSaving_success'
					/>
				}
			</div>
		</Fade>
	);
};

export default memo(Answers);
