import { memo, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeCategoryDataElem, changeCategoryDataEndpoint, changeCategoryDataEndpointEntry, changeTypeCategoryDataElem, changeTypeCategoryEndpoint, changeTypeCategoryEndpointEntry, changeTypeConditionEntryEndpoint, changeTypeConditionRunActionDataElem, changeTypeConditionRunActionEndpoint, changeTypeConditionRunActionEndpointEntry, changeTypeConditionRunEndpoint, changeValuesConditionRunActionDataElem, changeValuesConditionRunActionEndpoint, changeValuesConditionRunActionEndpointEntry, deleteCategoryDataElem, deleteCategoryDataEndpoint, deleteCategoryDataEndpointEntry, deleteConditionEntry, deleteConditionRun, deleteConditionRunActionDataElem, deleteConditionRunActionEndpoint, deleteConditionRunActionEndpointEntry, editConditionEntry, editConditionRun, selectDataList } from '../../../store/sesSlice';
import { changeCategoryDataException, changeCategoryDataRobot, changeTypeCategoryException, changeTypeCategoryRobot, changeTypeConditionRunActionExceptionRobot, changeTypeConditionRunActionRobot, changeValuesConditionRunActionExceptionRobot, changeValuesConditionRunActionRobot, deleteCategoryDataException, deleteCategoryDataRobot, deleteConditionRunActionExceptionRobot, deleteConditionRunActionRobot, selectRobot } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { CONDITION_RUN_ACTION_TYPE_LIST, OPERATION_CONDITIONS_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ConditionType, ConditionVariantType, DataElemActionEventType, IConditionData, IConditionVariable, OperationType } from '../../../types/sesTypes';
import { ExceptionType, RobotActionEventType } from '../../../types/sesRobotTypes';
import { IConditionRunProps } from './ConditionRun.props';
import styles from './ConditionRun.module.scss';

const ConditionRun = ({ conditionData, conditionBlockIdx, conditionIdx, isDisable, isAvailable, changeFlg, setChangeFlg, conditionFor }: IConditionRunProps): JSX.Element => {
	const [selectConditionType, setSelectConditionType] = useState<ConditionType>(conditionData.type); // тип условия запуска
	const [inputDepth, setInputDepth] = useState<number>(conditionData.type === 'data' ? (conditionData.depth) : 5); // глубина
	const [selectId, setSelectId] = useState<string>(conditionData.id); // id (название)
	const [selectOperation, setSelectOperation] = useState<OperationType>((conditionData.type === 'data' || conditionData.type === 'variable') ? conditionData.operation : 'exists'); // операция
	const [inputValue, setInputValue] = useState<string>((conditionData.type === 'data' || conditionData.type === 'variable') ? conditionData.value : ''); // значение

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const dataElemList = useAppSelector(selectDataList); // список элементов данных

	const translate = useTranslate(); // hook для перевода текста

	// следим за полем операций
	useEffect(() => {
		selectOperation === 'exists' && setInputValue(''); // если выбрано "Существует" - очищаем поле "Значение"
	}, [selectOperation]);

	// обработчик изменения типа условия запуска
	const changeTypeConditionRunHandler = (e: SelectChangeEvent<ConditionType>): void => {
		setSelectConditionType(e.target.value as ConditionType);
		if (conditionFor.for === 'action') {
			if (conditionFor.itsAction === 'robotEvent') {
				dispatch(changeTypeConditionRunActionRobot({
					actionEvent: conditionFor.actionEvent as RobotActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					conditionType: e.target.value as ConditionType,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'robotException') {
				dispatch(changeTypeConditionRunActionExceptionRobot({
					exception: conditionFor.actionEvent as ExceptionType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					conditionType: e.target.value as ConditionType,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'dataElement') {
				dispatch(changeTypeConditionRunActionDataElem({
					actionEvent: conditionFor.actionEvent as DataElemActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					conditionType: e.target.value as ConditionType,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpointEntry') {
				dispatch(changeTypeConditionRunActionEndpointEntry({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					conditionType: e.target.value as ConditionType,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpoint') {
				dispatch(changeTypeConditionRunActionEndpoint({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					conditionType: e.target.value as ConditionType,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		} else if (conditionFor.for === 'categoryAction') {
			if (conditionFor.itsAction === 'robotEvent') {
				dispatch(changeTypeCategoryRobot({
					actionEvent: conditionFor.actionEvent as RobotActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'robotException') {
				dispatch(changeTypeCategoryException({
					exception: conditionFor.actionEvent as ExceptionType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'dataElement') {
				dispatch(changeTypeCategoryDataElem({
					actionEvent: conditionFor.actionEvent as DataElemActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpointEntry') {
				dispatch(changeTypeCategoryEndpointEntry({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpoint') {
				dispatch(changeTypeCategoryEndpoint({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		} else if (conditionFor.for === 'endpointRun') {
			dispatch(changeTypeConditionRunEndpoint({
				conditionBlockIdx,
				conditionIdx,
				conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
			}));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
		} else if (conditionFor.for === 'endpointEntry') {
			dispatch(changeTypeConditionEntryEndpoint({
				conditionBlockIdx,
				conditionIdx,
				conditionType: e.target.value as Extract<ConditionType, 'data' | 'variable'>,
			}));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		}
	};

	const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
		switch (type) {
			case 'data':
				return {
					type,
					depth: inputDepth,
					id: selectId,
					operation: selectOperation,
					value: inputValue,
				};
			case 'variable':
				return {
					type,
					id: selectId,
					operation: selectOperation,
					value: inputValue,
				};
			case 'interval':
				return {
					type,
					id: selectId,
				};
			default:
				const defaultType: never = type;
				return defaultType;
		};
	};

	// обработчик изменения значений условия запуска
	const changeValuesConditionRunHandler = (): void => {
		if (conditionFor.for === 'action') {
			if (conditionFor.itsAction === 'robotEvent') {
				dispatch(changeValuesConditionRunActionRobot({
					actionEvent: conditionFor.actionEvent as RobotActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					data: creatingObjectWithCondition(conditionData.type),
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'robotException') {
				dispatch(changeValuesConditionRunActionExceptionRobot({
					exception: conditionFor.actionEvent as ExceptionType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					data: creatingObjectWithCondition(conditionData.type),
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'dataElement') {
				dispatch(changeValuesConditionRunActionDataElem({
					actionEvent: conditionFor.actionEvent as DataElemActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					data: creatingObjectWithCondition(conditionData.type),
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpointEntry') {
				dispatch(changeValuesConditionRunActionEndpointEntry({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					data: creatingObjectWithCondition(conditionData.type),
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpoint') {
				dispatch(changeValuesConditionRunActionEndpoint({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
					data: creatingObjectWithCondition(conditionData.type),
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		} else if (conditionFor.for === 'categoryAction') {
			if (conditionFor.itsAction === 'robotEvent') {
				dispatch((changeCategoryDataRobot({
					actionEvent: conditionFor.actionEvent as RobotActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					data: creatingObjectWithCondition(conditionData.type) as IConditionData,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'robotException') {
				dispatch(changeCategoryDataException({
					exception: conditionFor.actionEvent as ExceptionType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					data: creatingObjectWithCondition(conditionData.type) as IConditionData,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'dataElement') {
				dispatch((changeCategoryDataElem({
					actionEvent: conditionFor.actionEvent as DataElemActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					data: creatingObjectWithCondition(conditionData.type) as IConditionData,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpointEntry') {
				dispatch((changeCategoryDataEndpointEntry({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					data: creatingObjectWithCondition(conditionData.type) as IConditionData,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpoint') {
				dispatch((changeCategoryDataEndpoint({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
					data: creatingObjectWithCondition(conditionData.type) as IConditionData,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		} else if (conditionFor.for === 'endpointRun') {
			dispatch(editConditionRun({
				conditionBlockIdx,
				conditionIdx,
				data: creatingObjectWithCondition(conditionData.type) as IConditionData,
			}));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
		} else if (conditionFor.for === 'endpointEntry') {
			dispatch(editConditionEntry({
				conditionBlockIdx,
				conditionIdx,
				data: creatingObjectWithCondition(conditionData.type) as (IConditionData | IConditionVariable),
			}));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления условия
	const deleteConditionHandler = (): void => {
		if (conditionFor.for === 'action') {
			if (conditionFor.itsAction === 'robotEvent') {
				dispatch(deleteConditionRunActionRobot({
					actionEvent: conditionFor.actionEvent as RobotActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'robotException') {
				dispatch(deleteConditionRunActionExceptionRobot({
					exception: conditionFor.actionEvent as ExceptionType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'dataElement') {
				dispatch(deleteConditionRunActionDataElem({
					actionEvent: conditionFor.actionEvent as DataElemActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpointEntry') {
				dispatch(deleteConditionRunActionEndpointEntry({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpoint') {
				dispatch(deleteConditionRunActionEndpoint({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					conditionBlockIdx,
					conditionIdx,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		} else if (conditionFor.for === 'categoryAction') {
			if (conditionFor.itsAction === 'robotEvent') {
				dispatch((deleteCategoryDataRobot({
					actionEvent: conditionFor.actionEvent as RobotActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'robotException') {
				dispatch(deleteCategoryDataException({
					exception: conditionFor.actionEvent as ExceptionType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
				}));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'dataElement') {
				dispatch((deleteCategoryDataElem({
					actionEvent: conditionFor.actionEvent as DataElemActionEventType,
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpointEntry') {
				dispatch((deleteCategoryDataEndpointEntry({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else if (conditionFor.itsAction === 'endpoint') {
				dispatch((deleteCategoryDataEndpoint({
					channel: conditionFor.channel,
					channelIdx: conditionFor.channelIdx,
					categoryId: conditionFor.categoryId,
					categoryBlockIdx: conditionBlockIdx,
					categoryIdx: conditionIdx,
				})));
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		} else if (conditionFor.for === 'endpointRun') {
			dispatch(deleteConditionRun({
				conditionBlockIdx,
				conditionIdx,
			}));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
		} else if (conditionFor.for === 'endpointEntry') {
			dispatch(deleteConditionEntry({
				conditionBlockIdx,
				conditionIdx,
			}));
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		}
	};

	return (
		<div className={styles.condition}>
			{/* тип условия запуска */}
			<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0, '.MuiSelect-select': { paddingBlock: 0 }, }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={selectConditionType}
					onChange={changeTypeConditionRunHandler}
					disabled={isDisable}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{CONDITION_RUN_ACTION_TYPE_LIST.map(({ type, translation }) =>
						<MenuItem
							key={type}
							value={type}
							sx={{ fontSize: 13, color: colorPrimary }}
							disabled={type === 'interval' && (conditionFor.for === 'categoryAction' || conditionFor.for === 'endpointEntry' || conditionFor.for === 'endpointRun')}
						>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>

			{/* глубина */}
			{conditionData.type === 'data' &&
				<FormControl margin='dense' sx={{ flexShrink: 0 }}>
					<TextField
						label={translate('input_depth')}
						variant="outlined"
						type='number'
						value={inputDepth}
						onChange={(e) => setInputDepth(+e.target.value)}
						onBlur={(e) => conditionData.depth !== +e.target.value && changeValuesConditionRunHandler()}
						error={inputDepth < 1 || inputDepth > 20}
						disabled={isDisable}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
							inputProps: { min: 1, max: 20 },
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{/* название */}
			{conditionData.type === 'interval' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_name')}</InputLabel>
					<Select
						label={translate('select_name')}
						disabled={isDisable}
						value={selectId}
						onChange={(e) => setSelectId(e.target.value)}
						onBlur={(e) => conditionData.id !== e.target.value && changeValuesConditionRunHandler()}
						error={selectId === ''}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{/* на случай, если не найден интервал из списка */}
						{robotInfo.data && !Object.keys(robotInfo.data.timeintervals).includes(inputValue) && inputValue !== '' &&
							<MenuItem value={inputValue} sx={{ fontSize: 12, color: colorPrimary }}>{inputValue} ({translate('selectItem_notFound')})</MenuItem>
						}
						{robotInfo.data && Object.keys(robotInfo.data.timeintervals).map((name) =>
							<MenuItem key={name} value={name} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						)}
					</Select>
				</FormControl>
			}
			{conditionData.type === 'data' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_name')}</InputLabel>
					<Select
						label={translate('select_name')}
						disabled={isDisable}
						value={selectId}
						onChange={(e) => setSelectId(e.target.value)}
						onBlur={(e) => conditionData.id !== e.target.value && changeValuesConditionRunHandler()}
						error={selectId === ''}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
						title={dataElemList.dictionary[selectId]}
					>
						{/* не найденный элемент данных из списка */}
						{!dataElemList.data.find(elem => elem.id === selectId) && selectId !== '' &&
							<MenuItem value={selectId} sx={{ fontSize: 13, color: colorRed }}>{selectId} ({translate('selectItem_notFound')})</MenuItem>
						}
						{dataElemList.data.map((elem) => (
							<MenuItem key={elem.id} value={elem.id} sx={{ fontSize: 13, color: colorPrimary }}>{elem.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}
			{conditionData.type === 'variable' &&
				<FormControl fullWidth margin='dense'>
					<TextField
						label={translate('input_name')}
						variant="outlined"
						value={selectId}
						onChange={(e) => setSelectId(e.target.value)}
						onBlur={(e) => conditionData.id !== e.target.value && changeValuesConditionRunHandler()}
						disabled={isDisable}
						error={selectId === ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{(conditionData.type === 'data' || conditionData.type === 'variable') &&
				<>
					{/* операция */}
					<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<Select
							required
							value={selectOperation}
							onChange={(e) => setSelectOperation(e.target.value as OperationType)}
							onBlur={(e) => conditionData.operation !== e.target.value && changeValuesConditionRunHandler()}
							disabled={isDisable}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{OPERATION_CONDITIONS_LIST.map(({ operation, translation }) =>
								<MenuItem key={operation} value={operation} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>

					{/* значение */}
					<FormControl fullWidth margin='dense'>
						<TextField
							label={translate('input_value')}
							variant="outlined"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
							onBlur={(e) => conditionData.value !== e.target.value && changeValuesConditionRunHandler()}
							disabled={isDisable || selectOperation === 'exists'}
							error={inputValue === '' && selectOperation !== 'exists'}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				</>
			}

			{/* удаление условия */}
			{isAvailable &&
				<button
					className={styles.conditionTrashCan}
					onClick={deleteConditionHandler}
					title={translate('buttonTitle_deleteCondition')}
				>
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
					/>
				</button>
			}
		</div>
	);
};

export default memo(ConditionRun);
