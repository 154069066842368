import { useEffect, useRef, useState } from 'react';
import { Tree } from 'react-arborist';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addingParentToChild, changeParentClass, movingClass, removingParentFromChild, selectCorpus } from '../../store/corpusSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { CORPUS } from '../../constants/accessRights';
import { IContextMenuProps, IDialogProps, IFormattedData, IMoveHandlerProps } from '../../types/classTreeTypes';
import Node from './Node/Node';
import AlertDialog from '../AlertDialog/AlertDialog';
import { IClassTreeProps } from './ClassTree.props';
import styles from './ClassTree.module.scss';

const ClassTree = ({ setChangeFlg }: IClassTreeProps): JSX.Element => {
	const [formattedData, setFormattedData] = useState<IFormattedData[]>([]); // форматированные данные для дерева классов
	const [showAlertDialogMarkup, setShowAlertDialogMarkup] = useState<IDialogProps>({ isShow: false, childName: '', parentName: '', desc: '', type: 'addition' }); // показ диалогового окна после перемещения класса для доп.разметки
	const [parentClassList, setParentClassList] = useState<string[]>([]); // список родительских классов
	const [showContextMenu, setShowContextMenu] = useState<IContextMenuProps>({ isShow: false, x: 0, y: 0, who: '', from: '', level: 0, message: null }); // контекстное меню
	const containerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер дерева классов
	// const treeRef = useRef<TreeApi<IFormattedData> | undefined>(); // ссылка на дерево классов

	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за классами
	useEffect(() => {
		const usedClasses: string[] = []; // задействованные классы для построения дерева
		const data: IFormattedData[] = []; // форматированные данные для дерева классов
		const parentClasses: string[] = []; //  родительские классы

		// форматируем данные под дерево классов
		corpus.data.classes.forEach(([children, parent]) => {
			if (!usedClasses.includes(parent)) {
				usedClasses.push(parent);
				const tree: IFormattedData = {
					id: parent,
					name: parent,
					children: [],
				};
				children.forEach(child => {
					if (!usedClasses.includes(child) && tree.children) {
						tree.children.push({ id: child, name: child });
						usedClasses.push(child);
					}
				});
				data.push(tree);
			}
		});
		// оставшиеся классы без потомков
		corpus.classes.forEach(className => {
			!usedClasses.includes(className) && data.push({
				id: className,
				name: className,
			});
		});
		setFormattedData(data); // пишем форматированные данные в state

		// пишем родительские классы
		data.forEach(({ name, children }) => children && parentClasses.push(name));
		setParentClassList(parentClasses);
	}, [corpus.classes]);

	const moveHandler = ({ childName, childLevel, parentNameFrom, parentNameTo }: IMoveHandlerProps): void => {
		dispatch(movingClass({ child: childName, parent: parentNameTo })); // перемещаем класс
		setChangeFlg(true); // включаем флаг о не сохраненных данных
		if (parentNameTo) {
			// перемещение из одного родителя в другого
			if (childLevel === 1) {
				setShowAlertDialogMarkup({
					isShow: true,
					childName: childName,
					parentName: parentNameTo,
					oldParentName: parentNameFrom,
					desc: `${translate('dialog_murkupPhrasesChange_Confirm1')} "${parentNameFrom}" ${translate('dialog_onTitle')} "${parentNameTo}" ${translate('dialog_murkupPhrasesChange_Confirm2')} "${childName}"?`,
					type: 'change',
				}); // запрос на редактирование разметки
			} else {
				// перемещение из корня в родителя
				setShowAlertDialogMarkup({
					isShow: true,
					childName: childName,
					parentName: parentNameTo,
					desc: `${translate('dialog_murkupPhrasesAdd_Confirm1')} "${childName}" ${translate('dialog_murkupPhrasesAdd_Confirm2')} "${parentNameTo}" ${translate('dialog_murkupPhrasesAdd_Confirm3')}?`,
					type: 'addition',
				}); // запрос на редактирование разметки
			}
		} else {
			// перемещение из родителя в корень
			setShowAlertDialogMarkup({
				isShow: true,
				childName: childName,
				parentName: parentNameFrom,
				desc: `${translate('dialog_murkupPhrasesDelete_Confirm1')} "${parentNameFrom}" ${translate('dialog_murkupPhrasesDelete_Confirm2')} "${childName}"?`,
				type: 'deletion',
			}); // запрос на редактирование разметки
		}
		showContextMenu.isShow && setShowContextMenu(prev => { return { ...prev, isShow: false }; }); // закрываем контекстное меню, если было открыто
	};

	// дополнение разметкой
	const additionalMarkup = () => {
		switch (showAlertDialogMarkup.type) {
			case 'addition':
				dispatch(addingParentToChild({ child: showAlertDialogMarkup.childName, parent: showAlertDialogMarkup.parentName })); // добавление класса-родителя к классу-потомка в разметке корпуса
				break;
			case 'deletion':
				dispatch(removingParentFromChild({ child: showAlertDialogMarkup.childName, oldParent: showAlertDialogMarkup.parentName })); // удаление класса-родителя от класса-потомка в разметке корпуса
				break;
			case 'change':
				showAlertDialogMarkup.oldParentName && dispatch(changeParentClass({ child: showAlertDialogMarkup.childName, oldParent: showAlertDialogMarkup.oldParentName, newParent: showAlertDialogMarkup.parentName })); // изменение класса-родителя в разметке корпуса
				break;
			default:
				break;
		}

		setShowAlertDialogMarkup(prev => {
			return { ...prev, isShow: false };
		}); // закрываем диалоговое окно
	};

	return (
		<div className={styles.container} ref={containerRef}>
			<Tree
				// ref={treeRef}
				data={formattedData}
				// className={styles.tree}
				width="100%"
				height={containerRef.current?.clientHeight ? containerRef.current.clientHeight - 1 : undefined}
				indent={15} // отступ для потомков
				rowHeight={16} // высота строки
				onMove={({ dragIds, parentId, dragNodes }) => moveHandler({ childName: dragIds[0], childLevel: dragNodes[0].level, parentNameFrom: dragNodes[0].parent?.id || '', parentNameTo: parentId })}
				disableDrag={({ children }) => {
					// запрещаем перемещение родителям и если нет прав на редактирование
					if (children || !isAccess(CORPUS.SAVE)) return true;
					else return false;
				}}
				disableDrop={({ dragNodes, parentNode }) => {
					if (dragNodes[0].level === 0 && parentNode.level === 0) return false; // из корня в родителя
					else if (dragNodes[0].level === 1 && parentNode.level === -1) return false; // из родителя в корень
					else if (dragNodes[0].level === 1 && parentNode.level === 0 && dragNodes[0].parent?.id !== parentNode.id) return false; // из одного родителя в другой
					return true;
				}}
				// renderCursor={({ top, left, indent }) => <MyCursor top={top} left={left} indent={indent} />}
				renderCursor={() => <div />}
				children={({ node, style, dragHandle, tree }) => (
					<Node
						node={node}
						style={style}
						dragHandle={dragHandle}
						tree={tree}
						setChangeFlg={setChangeFlg}
						setShowContextMenu={setShowContextMenu}
					/>
				)}
			/>

			{/* контекстное меню */}
			{showContextMenu.isShow &&
				<div
					className={styles.contextMenuContainer}
					onMouseDown={() => setShowContextMenu(prev => { return { ...prev, isShow: false }; })}
				>
					<div
						className={styles.contextMenuWrapper}
						style={{ bottom: document.documentElement.clientHeight - showContextMenu.y, left: showContextMenu.x }}
						onMouseDown={e => e.stopPropagation()}
					>
						<p className={styles.contextMenuTitle}>
							{translate('title_moving')} "{showContextMenu.who}"
						</p>
						<ul className={styles.contextMenuList}>
							{(showContextMenu.message || parentClassList.length === 0) &&
								<li className={styles.contextMenuOptionNotActive}>
									{showContextMenu.message || translate('title_parentsMissing')}
								</li>
							}
							{parentClassList.find(parent => parent === showContextMenu.from) &&
								<li className={styles.contextMenuOption} onClick={() => moveHandler({
									childName: showContextMenu.who,
									childLevel: showContextMenu.level,
									parentNameFrom: showContextMenu.from,
									parentNameTo: null,
								})}>
									{translate('title_treeRoot')}
								</li>
							}
							{!showContextMenu.message && parentClassList.filter(parent => parent !== showContextMenu.from).map(parent => (
								<li className={styles.contextMenuOption} key={parent} onClick={() => moveHandler({
									childName: showContextMenu.who,
									childLevel: showContextMenu.level,
									parentNameFrom: showContextMenu.from,
									parentNameTo: parent,
								})}>
									{parent}
								</li>
							))}
						</ul>
					</div>
				</div>
			}

			<AlertDialog
				showAlertDialog={showAlertDialogMarkup.isShow}
				closeHandler={() => setShowAlertDialogMarkup(prev => ({ ...prev, isShow: false }))}
				submitHandler={additionalMarkup}
				title='dialog_murkupPhrases'
				description={showAlertDialogMarkup.desc}
			/>
		</div>
	);
};

export default ClassTree;
