import { useEffect, useState } from 'react';
import { Button, Fade, FormControl, InputLabel, MenuItem, Select, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addInterval, deleteInterval, editRobot, selectActiveRobotVersion, selectEditingRobot, selectRobot } from '../../store/sesRobotSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import TimeInterval from './TimeInterval/TimeInterval';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IIntervalsProps } from './Intervals.props';
import styles from './Intervals.module.scss';

const Intervals = ({ showIntervals, setShowIntervals }: IIntervalsProps): JSX.Element => {
	const [intervalName, setIntervalName] = useState<string>(''); // выбранное имя интервала
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const editingRobot = useAppSelector(selectEditingRobot); // store - статус изменения данных робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за id робота
	useEffect(() => {
		setChangeFlg(false); // сбрасываем флаг возможности сохранения
	}, [robotInfo.data?.id]);

	// следим за получением данных робота
	useEffect(() => {
		// если есть данные - вписываем в поле первое имя из списка
		if (robotInfo.data) {
			if (Object.keys(robotInfo.data.timeintervals).length > 0) {
				if (Object.keys(robotInfo.data.timeintervals).includes(intervalName)) setIntervalName(intervalName);
				else setIntervalName(Object.keys(robotInfo.data.timeintervals)[0]);
			}
			else setIntervalName('');
		}
	}, [robotInfo.data]);

	// следим за статусом изменения робота
	useEffect(() => {
		// если изменение данных робота прошло успешно
		if (editingRobot.status === RequestStatus.IDLE && editingRobot.error === ResponseStatus.SUCCESS && editingRobot.message !== '') {
			setChangeFlg(false); // сбрасываем флаг о несохраненных данных
		}
	}, [editingRobot]);

	// обработчик сохранения данных робота
	const editRobotHandler = (): void => {
		robotInfo.data && dispatch(editRobot({
			robotId: robotInfo.data.id,
			data: {
				timeintervals: robotInfo.data.timeintervals,
			}
		})); // изменяем данные робота
	};

	// обработчик добавления интервала
	const addIntervalHandler = (): void => {
		if (robotInfo.data) {
			const newName = `${translate('title_newInterval')} ${Object.keys(robotInfo.data.timeintervals).length + 1}`;
			dispatch(addInterval(newName)); // добавляем новый интервал
			setIntervalName(newName); // выбираем его
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления интервала
	const deleteIntervalHandler = (): void => {
		dispatch(deleteInterval(intervalName)); // удаляем интервал
		setIntervalName(''); // сбрасываем выбор интервала
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowIntervals(false);
	};

	return (
		<Slide direction="left" in={showIntervals} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.configRobot} onMouseDown={e => e.stopPropagation()}>

					{/* загрузка настроек робота */}
					{robotInfo.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения настроек робота */}
					{(robotInfo.status === RequestStatus.FAILED || robotInfo.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(robotInfo.message || 'title_loadFailed')}
						</div>
					}

					{/* настройки интервалов */}
					{robotInfo.status === RequestStatus.IDLE && robotInfo.data &&
						<Fade in={true} timeout={500}>
							<div className={styles.configRobotWrapper}>
								<div className={styles.configRobotTop}>
									{/* выбор интервала */}
									<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
										<InputLabel sx={{ fontSize: 13 }}>{translate('select_timeInterval')}</InputLabel>
										<Select
											required
											label={translate('select_timeInterval')}
											value={intervalName}
											onChange={e => e.target.value && setIntervalName(e.target.value)}
											style={{ fontSize: 13, height: 33, color: colorPrimary }}
										>
											{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
												<MenuItem sx={{ fontSize: 13 }} onClick={addIntervalHandler}>{translate('selectItem_addTimeInterval')}</MenuItem>
											}
											{Object.keys(robotInfo.data.timeintervals).map((name) =>
												<MenuItem key={name} value={name} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
											)}
										</Select>
									</FormControl>
									{/* временной интервал */}
									{Object.keys(robotInfo.data.timeintervals).includes(intervalName) ?
										<TimeInterval
											timeinterval={robotInfo.data.timeintervals[intervalName]}
											intervalName={intervalName}
											setIntervalName={setIntervalName}
											setChangeFlg={setChangeFlg}
										/>
										:
										<div className={styles.configRobotTopNotFoundTitle}>{translate('title_notFound')}</div>
									}
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.configRobotBottom}>
										{isAccess(SES.ROBOT_EDIT) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													disabled={!changeFlg}
													sx={{ fontSize: 11 }}
													onClick={editRobotHandler}
												>
													{translate('button_save')}
												</Button>
											</FormControl>
										}
										{isAccess(SES.ROBOT_EDIT) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													disabled={!Object.keys(robotInfo.data.timeintervals).includes(intervalName)}
													onClick={deleteIntervalHandler}
													color='error'
												>
													{translate('button_delete')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					<div className={styles.tagClose} onClick={closeHandler}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Intervals;
