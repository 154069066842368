import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addConditionRun, selectEndpointElem } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST, ConditionTypeExtended } from '../../../constants/robotConfigLists';
import { colorPrimary } from '../../../constants/colors';
import { IConditionData, IConditionVariable } from '../../../types/sesTypes';
import { IFormAddingConditionRunProps } from './FormAddingConditionRun.props';
import styles from './FormAddingConditionRun.module.scss';

const FormAddingConditionRun = ({ conditionBlockIdx, changeFlg, setChangeFlg }: IFormAddingConditionRunProps): JSX.Element => {
	const [conditionType, setConditionType] = useState<ConditionTypeExtended>('chooseType'); // тип условия запуска конечной точки

	const dispatch = useAppDispatch();
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка

	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления условия запуска конечной точки
	const addConditionRunHandler = (e: SelectChangeEvent<ConditionTypeExtended>): void => {
		const data: (IConditionData | IConditionVariable) = e.target.value === 'data' ?
			{
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			} : {
				type: 'variable',
				id: '',
				operation: 'exists',
				value: '',
			};

		setConditionType(e.target.value as ConditionTypeExtended);
		dispatch(addConditionRun({ conditionBlockIdx, data })); // добавляем условие
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
		setConditionType('chooseType'); // сбрасываем поле
	};

	return (
		<div className={cn(styles.condition, {
			[styles.containerOpacity]: conditionType === 'chooseType'
		})}>
			{/* тип условия запуска действия */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={conditionType}
					onChange={addConditionRunHandler}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST.filter(type => type.type !== 'interval').map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }} disabled={type === 'chooseType'}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingConditionRun;
