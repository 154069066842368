import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Slider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { COLORING_CONFIDENCE, COLORING_CONFIDENCE_THRESHOLD } from '../../../../constants/cookieNames';
import { backgroundColor } from '../../../../constants/colors';
import { IToggleColoringProps } from './ToggleColoring.props';
import styles from './ToggleColoring.module.scss';

const ToggleColoring = ({ styleNotActive, isAvailable }: IToggleColoringProps): JSX.Element => {
	const [thresholdValue, setThresholdValue] = useState<number>(0.7);

	const [cookies, setCookie] = useCookies([COLORING_CONFIDENCE, COLORING_CONFIDENCE_THRESHOLD]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// берем значение из cookie или по умолчанию
	useEffect(() => {
		setThresholdValue(+cookies.coloringConfidenceThreshold || 0.7);
	}, []);

	// функция изменения состояния раскрашивания достоверности слова в cookie
	const toggleColoring = (): void => {
		if (cookies.coloringConfidence === 'false') {
			setCookie(COLORING_CONFIDENCE, 'true', { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		} else {
			setCookie(COLORING_CONFIDENCE, 'false', { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		}
	};

	return (
		<div
			className={cn({
				[styles.button]: cookies.coloringConfidence !== 'false' && isAvailable,
				[styleNotActive]: !isAvailable,
				[styles.active]: cookies.coloringConfidence !== 'false',
			})}
			onClick={() => isAvailable && toggleColoring()}
			title={translate(cookies.coloringConfidence === 'false' ? 'buttonTitle_backlightOn' : 'buttonTitle_backlightOff')}
		>
			<FontAwesomeIcon
				icon={faPalette}
				size="2xl"
				color={backgroundColor}
			/>
			<div className={styles.rangeBlock} onClick={e => e.stopPropagation()} title=''>
				<span>0%</span>
				<Slider
					size="small"
					min={0}
					max={1}
					step={0.05}
					valueLabelDisplay='auto'
					value={thresholdValue}
					onChange={(_e, value) => setThresholdValue(value as number)}
					onChangeCommitted={(_e, value) => setCookie(COLORING_CONFIDENCE_THRESHOLD, value, { path: '/', maxAge: 2_592_000 })}
					valueLabelFormat={(value) => Math.round(value * 100)}
				/>
				<span>100%</span>
			</div>
		</div>
	);
};

export default ToggleColoring;
